import React from 'react'

import Percusion from '../../commons/assets/images/artist/1.jpg'
import Trombones from '../../commons/assets/images/artist/4.jpg'
import Baritonos from '../../commons/assets/images/artist/3.jpg'
import Clarinete from '../../commons/assets/images/artist/6.jpg'
import Trompetas from '../../commons/assets/images/artist/2.jpg'
import Tubas from '../../commons/assets/images/artist/5.jpg'

export default () =>
<div className="section section-padding artist-section">
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-md-offset-2 col-xs-12">
        <div className="section-header text-center">
          <h3 className="section-title">Quienes Somos</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="artists">
        <div className="col-md-4 col-xs-12">
          <div className="artist">
            <a className="artist-avatar">
              <img src={Percusion} alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Percusión</a>
            </h4>
            <p className="artist-role">
              Luis Bugueño
              <br /> Victor Daza
              <br /> Luis Pizarro
              <br /> Matias Herrera
              <br /> Fabrizio Lozano
              <br /> Lizardo Leon
              <br /> Ivan Vega
              <br /> Bastian Castillo
            </p>
          </div>
          <div className="artist">
            <a className="artist-avatar">
              <img src={Trombones} alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Trombones</a>
            </h4>
            <p className="artist-role">
              Felipe Malla
              <br /> Rodrigo Piñones
            </p>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <div className="artist">
            <a className="artist-avatar">
              <img src={Baritonos} alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Barítonos</a>
            </h4>
            <p className="artist-role">
              Francisco Pesos
              <br /> Fabian Maita
              <br /> Diego Campaña
              <br /> Marco Leiva
              <br /> Luis Chipoco
              <br /> Mayckol Vega
            </p>
          </div>
          <div className="artist">
            <a className="artist-avatar">
              <img src={Clarinete}alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Clarinete</a>
            </h4>
            <p className="artist-role">Jaime Fuentes</p>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <div className="artist">
            <a className="artist-avatar">
              <img src={Trompetas} alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Trompetas</a>
            </h4>
            <p className="artist-role">
              Alexandro Soto
              <br /> Fernando Cifuentes
              <br /> Luis Saez
              <br /> Erick Leon
              <br /> Ignacio Gonzalez
              <br /> Sebastian Chipoco
            </p>
          </div>
          <div className="artist">
            <a className="artist-avatar">
              <img src={Tubas} alt="..." />
            </a>
            <h4 className="artist-name">
              <a>Tubas</a>
            </h4>
            <p className="artist-role">
              Michael Leiva
              <br /> Camilo Bacian
              <br /> Maximiliano Montecinos
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
