import React from 'react'
const slides = [
    {
        title: "Tirana 2018",
        subtitle: "Diablada de Victoria",
        background: "url(https://scontent-sea1-1.xx.fbcdn.net/v/t31.0-8/20157423_10209203490515516_7279034601172259951_o.jpg?_nc_cat=105&_nc_ht=scontent-sea1-1.xx&oh=a63e82747314cb45c841663369a8518b&oe=5CD8DEF9)",
        primary: {
            icon: "fa-play",
            link: "https://www.youtube.com/playlist?list=PL8nuKud7Ih-vmL2UrYrcGyaVoqyX3kCkW",
            caption: "Ver la Playlist"
        },
        secondary: null
    }
]
const PrimaryLink = (item: any) =>
    <a className="btn btn-border btn-lg btn-black" href={ item.item.link } target="_blank">
        <i className={ "fa " + item.item.icon } /> { item.item.caption }
    </a>


const SecondaryLink = (item: any) =>
    <a className="btn btn-simple btn-lg play-video" href={ item.item.link } target="_blank">
        <i className={ "fa " + item.item.icon } /> { item.item.caption }
    </a>

export default () =>
    <div className="banner-slider owl-slider" id="banner-slider">
        {
            slides.map(i =>
                <div className="banner-item">
                    <div className="banner-background" style={{ backgroundImage: i['background'] }}/>
                    <div className="banner-mask" />
                    <div className="banner-content">
                        <div className="container">
                            <h3 className="banner-subtitle theme-color">{i['subtitle']}</h3>
                            <h1 className="banner-title">{ i['title'] }</h1>
                            <div className="button-group">
                                { i['primary'] ? <PrimaryLink item={i['primary']} /> : "" }
                                { i['secondary'] ? <SecondaryLink item={i['secondary']} /> : "" }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

    </div >
