import React from 'react';
import ReactDOM from 'react-dom';
import EnvironmentHelper from './commons/environment'
import Layout from './commons/layout'

import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";

EnvironmentHelper.bootstrap(
  () => {
    ReactDOM.render(
      <BrowserRouter>
        <Layout />
      </BrowserRouter>,
      document.getElementById('root')
    );
  },
  "http://www.runaukas.com",
  true
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
