import '../assets/scss/global.sass'
import * as React from 'react'
import Footer from './footer'
import Header from './header'
import Home from '../../views/home'
import { Route } from "react-router-dom";

export default () =>
  <div>
    <Header />
    <Route exact path="/" component={Home} />
    <Footer />
  </div>
