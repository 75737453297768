import React from 'react'
import logo from '../../assets/images/logo-rk.png'
export default () =>
    <div className="logo-social-area section-padding text-white">
        <div className="container text-center">
            <a className="logo logo-footer">
                <img src={logo} alt="Runaukas" />
            </a>
            <div className="footer-contact">
                <p className="phone"><i className="fa fa-phone"></i> +56-9-87491715</p>
                <p className="email"><i className="fa fa-envelope-o"></i> runaukas@runaukas.com</p>
            </div>
            <div className="socials">
                <a target="_blank" href="//open.spotify.com/artist/4BCJotdhl7rK9rrsmEF2bx">
                    <i className="fa fa-spotify"></i>
                </a>
                <a target="_blank" href="//facebook.com/internacional.runaukas/">
                    <i className="fa fa-facebook"></i>
                </a>
                <a target="_blank" href="//twitter.com/runaukas">
                    <i className="fa fa-twitter"></i>
                </a>
                <a target="_blank" href="//youtube.com/runaukas">
                    <i className="fa fa-youtube-play"></i>
                </a>
            </div>
        </div>
    </div>
