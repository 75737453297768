import Optional from 'typescript-optional'

export default class EnvironmentHelper {

    public static bootstrap = (callback: () => void, fallbackUrl: string, isProdSafe?: boolean) => {
        (EnvironmentHelper.isDev || isProdSafe) ? callback() : EnvironmentHelper.fallback(fallbackUrl)
    }

    public static fallback = (fallbackUrl: string) => {
        window.location.href = fallbackUrl
    }

    private static isDev: boolean = Optional.ofNullable(process.env.NODE_ENV)
    .filter(p => p === "development")
    .isPresent
}
