import React from 'react'
import Copyright from './copyright'
import Social from './social'
import Subscribe from './subscribe'

export default () =>
    <footer>
        <Social />
        <Copyright />
    </footer>
