import React from 'react'
import Slider from './slider'
import UpcomingAlbum from './upcoming_album'
import Musicians from './musicians'
import Interview from './interview'

export default () =>
    <div className="main-wrap">
        <Slider />
        <UpcomingAlbum />
        <Musicians />
        <Interview />
    </div>
