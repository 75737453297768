import React from 'react'
export default () =>
<div className="collapse navbar-collapse" id="navbar-collapse">
  <ul className="nav navbar-nav navbar-right">
    <li>
      <a href="//open.spotify.com/artist/4BCJotdhl7rK9rrsmEF2bx" target="_blank">
        <i className="fa fa-spotify"></i>
      </a>
    </li>
    <li>
      <a href="//youtube.com/runaukas" target="_blank">
        <i className="fa fa-youtube"></i>
      </a>
    </li>
    <li>
      <a href="//facebook.com/internacional.runaukas" target="_blank">
        <i className="fa fa-facebook"></i>
      </a>
    </li>
    <li>
      <a href="//twitter.com/runaukas" target="_blank">
        <i className="fa fa-twitter"></i>
      </a>
    </li>
  </ul>
</div>
