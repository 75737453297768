import React from 'react'
import logo from '../../assets/images/logo-rk.png'
export default () =>
    <div className="navbar-header">
        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse" aria-expanded="false">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
        </button>
        <a className="navbar-brand">
            <img src={logo} alt="Runaukas" />
        </a>
    </div>
