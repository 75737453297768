import React from 'react'
export default () =>
    <div className="copyright-footer">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 col-xs-12">
                    <p className="copyright">Copyright &copy; 2018 - <a href="/">Banda Internacional Runaukas</a></p>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <p className="credit-text"><a href="//oxozon.io"> 🇨🇱 </a></p>
                </div>
            </div>
        </div>
    </div>
