import React from 'react'
import Logo2007 from '../../commons/assets/images/album/intro-art-1.png'
import Logo2008 from '../../commons/assets/images/album/intro-art-2.png'
export default () =>
<div className="section upcoming-album-section">
  <div className="fluid-container">
    <div className="upcoming-album half black-bg">
      <div className="album-art">
        <img className="img-responsive" src={Logo2007} alt="Imponiendo sin Fronteras" />
      </div>
      <div className="album-content">
        <h3 className="album-title">Imponiendo sin Fronteras</h3>
        <p className="release-date">Año 2007</p>
        <a className="btn btn-border btn-white" href="//open.spotify.com/album/4LsKmjuu3KTPF2YbpJU4hl" target="_blank">
          Escuchar en Spotify
          <i className="fa fa-spotify"></i>
        </a>
      </div>
    </div>
    <div className="upcoming-album half gray-bg">
      <div className="album-art">
        <img className="img-responsive" src={Logo2008} alt="Marcando la Diferencia" />
      </div>
      <div className="album-content">
        <h3 className="album-title">Marcando la Diferencia</h3>
        <p className="release-date">Año 2008</p>
        <a className="btn btn-border btn-black" href="//soundcloud.com/runaukas/sets/marcando-la-diferencia" target="_blank">
          Escuchar en Soundcloud
          <i className="fa fa-soundcloud"></i>
        </a>
      </div>
    </div>
  </div>
</div>
