import React from 'react'
export default () =>
    <div className="section about-singer-section text-white">
        <div className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-7">
                        <div className="about-singer-content">
                            <h3 className="about-singer-title">Hola,
<br />
                                    Soy Francisco Pesos</h3>
                                <p className="about-singer-role">Director / Compositor</p>
                                <p className="about-singer-text">La banda se forma por querer hacer música con amigos y queríamos marcar la diferencia, instalar nuevo estilo.</p>
                                <div className="button-group">
                                    <a className="btn btn-simple btn-white" href="https://www.youtube.com/watch?v=tYgodSVVeVc" target="_blank">
                                        <i className="fa fa-play"></i> Entrevista
</a>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
