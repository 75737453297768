import React from 'react'
import Brand from './brand'
import Navbar from './navbar'
export default () =>
    <header id="top" className="top-header">
        <nav className="navbar navbar-default" data-spy="affix" data-offset-top="400">
            <div className="container-fluid">
                <Brand></Brand>
                <Navbar></Navbar>
            </div>
        </nav>
    </header>
